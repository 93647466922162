<template>
  <v-card flat>
    <v-card-text class="pa-1 pt-0 pb-0">
      <div class="text-center" v-if="loading">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <template v-for="(item, index) in listOfSheets">
        <ccsheet-item :key="index" :model="item"></ccsheet-item>
      </template>
      <template v-if="listOfSheets && listOfSheets.length <= 0">
        {{ $t('message.ccsheet.noSheetFound') }}
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import CONSTANTS from '@/assets/json/constants.json'
import { hostAppApi } from '@/plugins/axios_settings'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: false,
      listOfSheets: []
    }
  },
  components: {
    'ccsheet-item': () => import('@/components/CCSheet/Item')
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId'])
  },
  mounted () {
    requestAnimationFrame(() => {
      if (this.$refs.scanFocus) {
        this.$refs.scanFocus.focus()
      }
    })
    this.getActiveSheeets()
  },
  methods: {
    getActiveSheeets () {
      this.loading = true
      hostAppApi.get(`${this.getHostRefApi}ccsheets/get_ccsheets_for_pwa?user_id=${this.userId}`)
        .then((response) => {
          if (response.data) {
            const sheets = response.data
            this.listOfSheets = sheets.filter(x => x.status === CONSTANTS.ON_GOING || x.status === CONSTANTS.RECOUNT || x.status === CONSTANTS.COUNTED)
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
